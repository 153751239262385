import React from "react"
import NonbmData from "./data/nonbm.json"
import CtaDisclosureSearch from "@tightrope/ctadisclosuresearch"
import Styles from "./css/nonbma.module.scss"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"

class NonbmCat extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: 'none',
      data: props.data
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    if (typeof window != "undefined") {
        window.mapParams();
    }

    });



      //stepgrid

      var stepGrid = document.querySelectorAll('.nonbma-module--stepGrid');
      Array.from(stepGrid).forEach(step => {
          step.addEventListener('click', function(event) {
              let selection = event.target.children.length > 0 ? event.target.innerText : (event.target.className == "stepName") ? event.target.innerText : event.target.nextSibling.innerText;
              document.getElementsByTagName('h3')[0].innerHTML = `Print and browse <b>${selection}</b> from right your desktop with Easy Print by Clear`;
          });
      });


  }

  render() {
    return (
      <div className = {Styles.nonbmcat} style={{ display: this.state.display }}>
        <div className={Styles.atfGrid}>
        	<div className={Styles.header}>
            <span className={Styles.headerWrap}>
              <img src={this.state.data.secondaryLogo}/>
              <img src={this.state.data.mainLogo}/>
            </span>
          </div>

        	<div className={Styles.center}>
        		<h1 className="hc_param">{this.state.data.headline}</h1>
        		<h3 className={Styles.subhead} >{this.state.data.subhead}</h3>

              <button className={`${Styles.install} cl1`} data-cy="cta" onClick = {() => window.triggerInstall()}
                dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }}></button>
              {this.props.children}


        	</div>

        	<div className={Styles.install}>
        		<h2>{this.state.data.lowerHeadline}</h2>
        			<div className={Styles.installGrid}>
        				<a className={Styles.stepGrid}>
        					  <img className = {Styles.installIcon} src={this.state.data.cat1img} />
        					<span className = 'stepName' dangerouslySetInnerHTML={{ __html: this.state.data.cat1copy}}></span>
        				</a>
        				<a className={Styles.stepGrid}>
                  <img className = {Styles.installIcon} src={this.state.data.cat2img} />
                <span className = 'stepName' dangerouslySetInnerHTML={{ __html: this.state.data.cat2copy}}></span>
        				</a>
        				<a className={Styles.stepGrid}>
                  <img className = {Styles.installIcon} src={this.state.data.cat3img} />
                <span className = 'stepName' dangerouslySetInnerHTML={{ __html: this.state.data.cat3copy}}></span>
        				</a>
                <a className={Styles.stepGrid}>
                  <img className = {Styles.installIcon} src={this.state.data.cat4img} />
                <span className = 'stepName' dangerouslySetInnerHTML={{ __html: this.state.data.cat4copy}}></span>
        				</a>
                <a className={`${Styles.stepGrid} `}>
                  <img className = {Styles.installIcon} src={this.state.data.cat5img} />
                <span className = 'stepName' dangerouslySetInnerHTML={{ __html: this.state.data.cat5copy}}></span>
        				</a>
                <a className={Styles.stepGrid}>
                  <img className = {Styles.installIcon} src={this.state.data.cat6img} />
                <span className = 'stepName' dangerouslySetInnerHTML={{ __html: this.state.data.cat6copy}}></span>
        				</a>
                <a className={Styles.stepGrid}>
                  <img className = {Styles.installIcon} src={this.state.data.cat7img} />
                <span className = 'stepName' dangerouslySetInnerHTML={{ __html: this.state.data.cat7copy}}></span>
        				</a>
                <a className={Styles.stepGrid}>
                  <img className = {Styles.installIcon} src={this.state.data.cat8img} />
                <span className = 'stepName' dangerouslySetInnerHTML={{ __html: this.state.data.cat8copy}}></span>
        				</a>
        			</div>

        		<div className={Styles.br}>
        		Tightrope Interactive | <a href="/terms-of-service/" target="_blank">Terms</a> | <a href="/privacy-policy/" target="_blank">Privacy</a> | <a href="/contact-us/" target="_blank">Contact</a> | <a href="/uninstall/" target="_blank">Uninstall</a> | <a href="/unsubscribe/"  target="_blank">Unsubscribe</a>  <br/> <a href="/privacy-policy/#cali-consumer" target="_blank">Do Not Sell My Personal Information</a> | All trademarks are property of their respective owners
        		</div>
        	</div>

        </div>
            </div>






    )
  }
}

export default NonbmCat
