import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import NonbmCat from "src/components/nonbmCat"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import Btf_cb from "src/components/btf_cb"
import ClearCtaSimple from "src/components/clearCtaSimple"


const categoriesData = import("./data/data.json");
const btfData = import("./data/btfData.json");

export default function nonbmCategories() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`

       #ctadisclosuresearch-module--ctadisclaimersearch {
         width: 100%;
         max-width: 800px;
         margin: 0 auto;
       }
       #ctadisclosurecb2-module--ctadisclaimer {
         display: none;
       }
       #clearctasimple-module--clearctasimple p {
         max-width: 506px;
         margin-top: 20px;
       }
       .btf_cb-module--btfCb button.btf_cb-module--install {
         background-color: #1173FA;
       }
    `}
    </style>
    <style type="text/css">
         </style>
    <title>Print any web page easily - Easy Print</title></Helmet>
    <section>
      <NonbmCat data={categoriesData}><ClearCtaSimple></ClearCtaSimple></NonbmCat>
      <Btf_cb data={btfData}><ClearCtaSimple></ClearCtaSimple></Btf_cb>
      </section>
    </HomepageLayout>
  )
}
